import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-stockton-california.png'
import image0 from "../../images/cities/scale-model-of-haggin-museum-in-stockton-california.png"
import image1 from "../../images/cities/scale-model-of-wat-dhammararam-buddhist-temple-in-stockton-california.png"
import image2 from "../../images/cities/scale-model-of-cortez-park-in-stockton-california.png"
import image3 from "../../images/cities/scale-model-of-visit-stockton-in-stockton-california.png"
import image4 from "../../images/cities/scale-model-of-choice-travel-&-tour-in-stockton-california.png"
import image5 from "../../images/cities/scale-model-of-delta-charter-bus-in-stockton-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Stockton'
            state='California'
            image={image}
            lat='37.9763'
            lon='-121.3133'
            attractions={ [{"name": "Haggin Museum", "vicinity": "1201 N Pershing Ave, Stockton", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 37.9605722, "lng": -121.31386090000001}, {"name": "Wat Dhammararam Buddhist Temple", "vicinity": "3732 Carpenter Rd, Stockton", "types": ["place_of_worship", "point_of_interest", "establishment"], "lat": 37.9243986, "lng": -121.22519090000003}, {"name": "Cortez Park", "vicinity": "8401 Tam O'Shanter Dr, Stockton", "types": ["park", "point_of_interest", "establishment"], "lat": 38.0259759, "lng": -121.30233069999997}, {"name": "Visit Stockton", "vicinity": "125 Bridge Pl, Stockton", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 37.9545574, "lng": -121.28951740000002}, {"name": "Choice Travel & Tour", "vicinity": "4021 Dakota Dr, Stockton", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 37.909822, "lng": -121.32130599999999}, {"name": "Delta Charter Bus", "vicinity": "4900 E Mariposa Rd, Stockton", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 37.9266184, "lng": -121.2158791}] }
            attractionImages={ {"Haggin Museum":image0,"Wat Dhammararam Buddhist Temple":image1,"Cortez Park":image2,"Visit Stockton":image3,"Choice Travel & Tour":image4,"Delta Charter Bus":image5,} }
       />);
  }
}